import React from 'react'

import SectionDividerSimple from './sectionHeader'

function partners() {
    return (
        <div className="container">
            <SectionDividerSimple name={'Partners'} />

            <div className="row">
                <div className="col-12">
                    <p className="aboutbrieftext">
                        Industry 5.0 will be about collaboration more than the underline technologies. At Factory 127, we understand that collaboration is a very vital key to a successful community. We have partnered up with the following companies/organizations that show the same spirit. 
                    </p>
                </div>
            </div>
            <br />
            <hr />
            <div className="row">
                <div className="col-4 partnerLogoBg text-center">
                    <img src="/images/Tampere_University.png" className="img-fluid" alt="Tampere University"></img>
                </div>
                <div className="col-8">
                    <p className="aboutbrieftext">The <a href="https://research.tuni.fi/pirg/">Pervasive Interaction Research Group</a> (PIRG) at Tampere University in Finland, under the supervision of <a href="https://research.uta.fi/pirg/markku-turunen/">Dr. Markku Turunen</a>, led the innovation of user experience and infrastructure/software implementation in the Internet of Things in the DIEM, Smart Light Environments, and EneryLand projects.</p>
                    <p className="aboutbrieftext">Members of Factory 127 have worked on various projects with the PIRG group. Some of the projects were published in a book chapter available in <a href="https://books.google.ca/books?id=0aQzDwAAQBAJ&pg=PT332&lpg=PT332&dq=%22salim+haniff%22+%22internet+of+things%22&source=bl&ots=nQWxsCngdu&sig=ACfU3U0m-qLGG3uHHchPVtDl17uIqYN9_A&hl=en&sa=X&ved=2ahUKEwiUuurBiIPsAhVSGs0KHQnjC2UQ6AEwBHoECAEQAQ#v=onepage&q=%22salim%20haniff%22%20%22internet%20of%20things%22&f=false">From Internet of Things to Smart Cities: Enabling Technologies</a></p>
                </div>
            </div>
            <hr />
        </div>
    )
}

export default partners
