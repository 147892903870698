import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export class navbarSimple extends Component {
    render() {
        return (
            <ul className="navBarSimple">
                <li><NavLink to="/">Factory 127</NavLink></li>
                <li><NavLink to="/about" className="borderShadow" activeClassName="activeLink">About</NavLink></li>
                <li><NavLink to="/service" className="borderShadow" activeClassName="activeLink">Services</NavLink></li>
                <li><NavLink to="/partners" className="borderShadow" activeClassName="activeLink">Partners</NavLink></li>
                <li><NavLink to="/contact" className="borderShadow">Contact</NavLink></li>
            </ul>
        )
    }
}

export default navbarSimple
