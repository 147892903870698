import React, { Component } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import SectionHeader from '../components/sectionHeader'

export class ServiceCodeView extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <Header />
                </div>
                <div className="row">
                    <SectionHeader name="CODE SERVICES" />
                    
                    <p className="text-justify aboutbrieftext">Starting in the 2000s, we started off developing content for interactive television on the WebTV platform. Since there were no frameworks or guidelines to follow, we had to create our interactions based on the available Internet rich media content at that time. This largely entailed utilizing a Linux/Apache/PostgreSQL/PHP stack to help deliver personalized interactive content to the viewer. To further enhance the experience we utilized more application rich content offered through Flash and Director.</p>
                    <p className="text-justify aboutbrieftext">As we moved to more elaborate collaboration projects requiring real-time data distributed to a larger community base, we created applications through a mix of technologies ranging from Java, C and Python over multicast. As the IoT market started to thrive we moved to develop software solutions that allow us to create IoT analytics and smart home interactions. This involved creating platforms on Raspberry Pis and other embedded systems to connect sensors and actuators to the network.</p>
                    <p className="text-justify aboutbrieftext">From there we have continuously updated our application stacks to incorporate more modern technologies like MERN, Java, Python and JavaScript.</p>
                    <br />
                    <h2 className="ServiceTitle">Services offered:</h2>
                    <dl className="aboutbrieftext">
                        <dt>Web applications</dt>
                        <dd className="text-justify">Having a background developing database-driven websites using LAMP stacks to more modern React/Redux, also rich interactive webapps, we can offer solutions to help meet the needs of you and your clients.</dd>
                        <dt>Mobile development</dt>
                        <dd className="text-justify">We have developed applications in iOS and Android to control IoT devices and other electronic devices.</dd>
                        <dt>Physical Computing</dt>
                        <dd className="text-justify">Created applications on the Raspberry Pi that allow users to interact with computers through different modalities.</dd>
                    </dl>
                </div>
                <div className="row">
                    <SectionHeader name="CONTACT" />
                    <Footer />
                </div>
            </div>
        )
    }
}

export default ServiceCodeView
