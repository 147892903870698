import React, { Component } from 'react'

export class header extends Component {
    render() {
        return (
            <div className="col-12">
                <img src="/images/retro_f127.png" className="img-fluid" alt="Retro Factory 127"/>
            </div>
        )
    }
}

export default header
