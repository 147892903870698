import React, { Component } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import SectionHeader from '../components/sectionHeader'

export class ServiceCircuitView extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <Header />
                </div>
                <div className="row">
                    <SectionHeader name="CIRCUIT SERVICES" />
                    
                    <p className="text-justify aboutbrieftext">Most of our projects have involved human interaction with computer systems. We have developed skills overtime to create circuit boards to incorporate into our end-to-end solutions. Our circuits range from sensors to acquire data from environments, biological signals, robotics, and custom user interfaces.</p>
                    <br />
                    
                    <h2 className="ServiceTitle">Services offered:</h2>
                         
                    <div className="row">
                        <div className="col-12">
                            <dl className="aboutbrieftext">
                                <dt>IoT Solutions</dt>
                                <dd className="text-justify">Development of circuits specifically for IoT applications.</dd>
                                <dt>Custom User Interfaces</dt>
                                <dd className="text-justify">Development of physical interfaces to interact with computer systems.</dd>
                                <dt>Physical Computing</dt>
                                <dd className="text-justify">Development of interactive/immersive installations </dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <SectionHeader name="CONTACT" />
                    <Footer />
                </div>
            </div>
        )
    }
}

export default ServiceCircuitView
