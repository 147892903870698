import React, { Component } from 'react'
import Header from '../components/header'
import Services from '../components/services'
import Footer from '../components/footer'
import SectionDividerSimple from '../components/sectionHeader'

export class Service extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <Header />
                </div>
                <div className="row">
                    <SectionDividerSimple name="SERVICES" />
                    <Services />
                </div>
                <div className="row">
                    <SectionDividerSimple name="CONTACT" />
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Service
