import React, { Component } from 'react';
import './App.css';
import NavbarSimple from './components/navbarSimple'


import Home from './views/Home'
import About from './views/About'
import Service from './views/Service'
import Partner from './views/Partner'
import CloudService from './views/ServiceCloudView'
import CodeService from './views/ServiceCodeView'
import CircuitService from './views/ServiceCircuitView'
import Contact from './views/ContactView'

import { BrowserRouter, Switch, Route } from 'react-router-dom';

// function App() {
//   return (
//     <div className="App">
//       <div className="container">
//         <div className="row justify-content-center">
//         <NavbarSimple />
//         </div>
//         <div className="row">
//           <Header />
//         </div>
//         <div className="row">
//           <SectionHeader name="ABOUT" />
//           <AboutSlug />
//         </div>
//         <div className="row">
//           <SectionHeader name="SERVICES" />
//           <Services />
//         </div>
//       </div>
//     </div>
//   );
// }

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <NavbarSimple />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/about' component={About} />
            <Route path='/service/cloud' component={CloudService} />
            <Route path='/service/code' component={CodeService} />
            <Route path='/service/circuit' component={CircuitService} />
            <Route path='/service' component={Service} />
            <Route path='/partners' component={Partner} />
            <Route path='/contact' component={Contact} />
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}

export default App;
