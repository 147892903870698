import React from 'react'

function contact() {
    return (
        <div>
            contacts
        </div>
    )
}

export default contact
