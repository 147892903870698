import React, { Component } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import SectionHeader from '../components/sectionHeader'

export class ServiceCloudView extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <Header />
                </div>
                <div className="row">
                    <SectionHeader name="CLOUD SERVICES" />
                    
                    <p className="text-justify aboutbrieftext">Having started in the IT sector "rack and stacking" servers per solution to utilizing Infrastructure-as-Code, we have been through many iterations of the data center. Our journey into virtualization and cloud technology kicked off in the mid-2000s when servers started to migrate from physical to virtual.</p>
                    <p className="text-justify aboutbrieftext">We started utilizing OpenStack (Havana) in 2013 to help offer an on-premise cloud solution that allowed us to deploy projects rapidly. Some of these projects entailed IoT solutions and testing of Big Data (Hadoop) components. We chose the open-source route due to the cost/resource restriction placed on us. We realized that rebuilding OpenStack and solutions needed to be managed through code to ensure consistent rollouts and ensure we can control new additional features. We adopted deployment technologies such as Chef/Puppet, Ansible, and Terraform.</p>
                    <p className="text-justify aboutbrieftext">We later chose to stick with Terraform and Ansible since it allowed us to deploy solutions from minimal infrastructure in place. As more changes to projects occurred, we moved to a DevOps strategy and split the environment up to Dev and Prod to help control the changes in the solution.</p>
                    <p className="text-justify aboutbrieftext">Today, we are using what we have learned from our past to deploy solutions utilizing modern cloud technologies like public clouds (AWS/Azure). Our deployments range from using cloud-native services/tools or cloud-agnostic tools (e.g. Hashicorp Vault/Terraform, HAProxy, various DBs, and Ansible).
</p>
                    <br />
                    <h2 className="ServiceTitle">Services offered:</h2>
                    <dl className="aboutbrieftext">
                        <dt>Solutions Architect</dt>
                        <dd className="text-justify">Having gained a wealth of knowledge from working on various projects in our past. We know the correct services and tools to utilize in deploying full strategies to help your company/community meet your client needs.</dd>
                        <dt>Infrastructure-as-Code scripting</dt>
                        <dd className="text-justify">Leveraging common tools (Ansible/Terraform) used in the Open Source industry, we can create custom code to help create your cloud solutions.</dd>
                    </dl>
                </div>
                <div className="row">
                    <SectionHeader name="CONTACT" />
                    <Footer />
                </div>
            </div>
        )
    }
}

export default ServiceCloudView
