import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export class services extends Component {
    render() {
        return (
            <div className="container">  
                <div className="row row-eq-height">
                <div className="col-md-4">
                    <img className="img-fluid" src="/images/neon_cloud.png" alt="Cloud"/>
                </div>
                <div className="col-md-8">
                    <h3 className="ServiceTitle text-left">Cloud</h3><br/>
                    <p className="text-white">Extensive experience in automating and orchestrating solutions using Open Source products for private, hybrid or public cloud. Whether the clouds are Azure/AWS/OpenStack or on-premise virtualization technologies, we can work with you in automating and orchestrating solutions.</p> <br /><br />
                    <p className="text-right"><NavLink to="/service/cloud">Read more...</NavLink></p>
                </div>
            </div>
            <hr />
            <div className="row row-eq-height">
                <div className="col-md-4">
                    <img className="img-fluid" src="/images/neon_code.png" alt="code"/>
                </div>
                <div className="col-md-8">
                    <h3 className="ServiceTitle text-left">Code</h3><br/>
                    <p className="text-white">Having developed for a wide audience group, ranging from web-centric applications to mobile devices, we have designed and implemented various solutions using Android, iOS and HTML5 technologies. In addition, we have also developed applications using C/C++, Java, JavaScript, PHP and Python. We are always learning new technologies or languages.</p>
                    <p className="text-right"><NavLink to="/service/code">Read more...</NavLink></p>
                </div>
            </div>
            <hr />
            <div className="row row-eq-height">
                <div className="col-md-4">
                    <img className="img-fluid" src="/images/neon_chip.png" alt="chip"/>
                </div>
                <div className="col-md-8">
                    <h3 className="ServiceTitle text-left">Circuits</h3><br/>
                    <p className="text-white">With a background in developing circuit boards for robitics, interactive and pervasive computing, we have also done work to help clients deliver an end-to-end IoT solutions.</p><br /><br /><br /><br /><br />
                    <p className="text-right"><NavLink to="/service/circuit">Read more...</NavLink></p>
                </div>
            </div>
            <hr />  
            </div>
        )
    }
}

export default services
