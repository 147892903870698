import React, { Component } from 'react'

export class sectionHeader extends Component {
    render() {
        return (
            <div className="col-md-12 section-header">
                <p className="text-center">{this.props.name}</p>
            </div>
        )
    }
}

export default sectionHeader
