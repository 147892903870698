import React from 'react'

import SectionDividerSimple from './sectionHeader'
import Profile from './profile'

function main() {
    return (
        <div className="container">
            <SectionDividerSimple name={'About Us'} />
            <div className="row row-eq-height">
                
                <p className="aboutbrieftext">Factory 127 is an Industry 4.0 company specializing in cloud, code, and circuits. Having 20 years of experience as solution architects for the media and healthcare sectors in Toronto, in addition to various organizations ranging from early IoT companies to three of the internationally recognized companies in Finland, we have implemented numerous multimedia, end-to-end IoT, and workflow optimization solutions.</p>
                <p className="aboutbrieftext">Our experiences and being involved in many projects have shown us consistent patterns when it comes to the incorporation of cloud, code, and circuity. The need to have room to experiment with new ideas and be allowed to fail is an area that has been avoided due to costs and risks.</p>
                <p className="aboutbrieftext">Most of the costs are usually due to sourcing technology that may be expensive and not fulfill the need of the projects. The risks are delayed timelines and under-delivering on expectations. These issues have already been dealt with by numerous companies that have empowered them to become market leaders. These same technologies are also available to you. With our experiences, we can enable your company to utilize these same technologies to achieve new growth for your companies.</p>
                <p className="aboutbrieftext">Factory 127 was created to help companies/communities become more self-sufficient and grow skillsets within their companies/communities. The '127' in our name refers to the Internet address 127.0.0.1 meaning localhost. We strongly believe in empowering locally and building foundations to help become market leaders. </p>
            </div>
            <SectionDividerSimple name={'The Team'} />
            <div className="row row-rq-height">
                
                <div className="mx-auto">
                        <Profile name={'Salim Haniff'} image={'/images/salim.JPG'} linkedin={'https://www.linkedin.com/in/salim-haniff/'} medium={'https://www.medium.com/@salimwp'} desc={'Salim has 20 years of experience building full end-to-end solutions from multimedia projects, web and IoT solutions using DevOps and hybrid cloud.'}/>
                </div>
                
            </div>
        </div>
    )
}

export default main