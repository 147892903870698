import React, { Component } from 'react'
import Header from '../components/header'
import AboutUs from '../components/aboutus'
import Footer from '../components/footer'
import SectionHeader from '../components/sectionHeader'

export class About extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <Header />
                </div>
                <div className="row">
                    <AboutUs />
                </div>
                <div className="row">
                    <SectionHeader name="CONTACT" />
                    <Footer />
                </div>
            </div>
        )
    }
}

export default About
