import React from 'react'

function main(props) {
    return (
        <div className="card" style={{width: 18 + 'rem'}}>
            <img className="card-img-top" src={ props.image } alt="Profile" />
            <div className="card-body">
                <h5 className="card-title">{ props.name }</h5>
                <p className="card-text">{ props.desc }</p>
                <a href={ props.linkedin }><img src="/images/linkedin.svg" alt="LinkedIn" /></a>
                { props.medium ? <a href={ props.medium }> <img src="/images/medium.png" alt="Medium" width="24px"/></a> : null }
            </div>
        </div>
    )
}

export default main
