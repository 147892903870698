import React, { Component } from 'react'
import Header from '../components/header'
import Contact from '../components/contact'
import Footer from '../components/footer'
import SectionHeader from '../components/sectionHeader'

export class ContactView extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <Header />
                </div>
                <Contact />
                <div className="row">
                    <SectionHeader name="CONTACT" />
                    <Footer />
                </div>
            </div>
        )
    }
}

export default ContactView
