import React from 'react'
import { NavLink } from 'react-router-dom'

function footer() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-4">
                    <p className="aboutbrieftext">While our journey has started, feel free to contact us: </p>
                    <p className="aboutbrieftext">hello at factory127 dot com</p>
                </div>
                <div className="col-4">
                    <p className="aboutbrieftext">Follow us:</p>
                    <p className="aboutbrieftext"><a href="https://twitter.com/factory_127"><i className="socialIcons fab fa-twitter"></i></a> <a href="https://www.instagram.com/therealfactory127/"><i className="socialIcons fab fa-instagram"></i></a> <a href="https://github.com/salimwp"><i class="socialIcons fab fa-github"></i></a> <br /><a href="https://www.medium.com/@salimwp"><i className="socialIcons fab fa-medium-m"></i></a><br /> <a href="https://linkedin.com/company/factory-127"><i class="socialIcons fab fa-linkedin-in"></i></a>   </p>
                </div>
                <div className="col-4">
                    <p className="aboutbrieftext">Services</p>
                    <p className="aboutbrieftext">
                        <NavLink to="/service/cloud">Cloud</NavLink><br />
                        <NavLink to="/service/code">Code</NavLink><br />
                        <NavLink to="/service/circuit">Circuits</NavLink>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default footer
