import React, { Component } from 'react'

export class aboutSlug extends Component {
    render() {
        return (
            <div className="aboutSlug">
                <p>Woah, retro really? It is the 2020s...</p>
                <p>Understandably that the time difference between the 1980s and 2020s is quite substantial. However, if you were to look at the technologies available in the 1980s, they helped enabled people to create their visions or solve their problems. The culture at the time was self-sufficiency and hackerish.</p> 
                <p>The Internet helped spread information around quicker and into the hands of people that can utilize it. However, the Internet and globalization helped create a rift in society and outsourced the development of goods and knowledge, leaving societies with gaps.</p>
                <p>There is now another industrial revolution currently underway where the concepts of the 1980s DIYers are quickly merging with the technological innovations of the modern era. This is known as Industry 4.0 and the sector that Factory 127 is innovating in.</p>
            </div>
        )
    }
}

export default aboutSlug
